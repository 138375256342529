<script lang="ts" setup>
import type { KeenSliderOptions } from "keen-slider";

import type { GamesPayload } from "@/types";

const props = withDefaults(
	defineProps<{
		type?: "link" | "button";
		menu: GamesPayload["menu"];
		showLobby?: boolean;
	}>(),
	{ type: "link" }
);

const sliderRef = ref();
const { t } = useT();
const isGuest = useIsGuest();
const { isDesktop } = useDevice();
const { isTabActive, getTitle, lobbyActive, activeUrl } = useGameNavigator();
const { isExpanded } = useGameSearch();
const showCatalogGames = useState<boolean>("show-catalog-games");
const emit = defineEmits<{ (event: "clickTab", link: string): void }>();
const sliderActiveSlide = ref(0);
const isLast = ref(false);
const { open } = useTaoModals();

const filteredMenu = computed(() => props.menu?.filter((item) => !(isGuest.value && item.title === "Favorites")));
const totalSlides = computed(() => (filteredMenu.value?.length ?? 0) + (props.showLobby ? 1 : 0));
const slideOffset = computed(() => (isDesktop ? 2 : 1));

const activeSlide = computed(() => {
	const menu = props.menu || [];
	const index = menu.findIndex((item) => isTabActive(item?.url || ""));

	return index !== -1 ? index + 1 : slideOffset.value;
});

const scrollToActiveSlide = () => {
	setTimeout(() => {
		if (sliderRef.value) {
			const { slider } = sliderRef.value;
			const maxIdx = slider?.track?.details?.maxIdx ?? 0;
			const slideToMove = Math.min(activeSlide.value - slideOffset.value, maxIdx);

			slider?.moveToIdx(slideToMove, false, { duration: 800 });
		}
	}, 500);
};

const sliderOptions: KeenSliderOptions = {
	loop: false,
	mode: "snap",
	slides: { perView: "auto", spacing: 3 },
	created(slider) {
		sliderActiveSlide.value = slider.track.details.abs;
	},
	slideChanged(slider) {
		sliderActiveSlide.value = slider.track.details.abs;
	},
	dragged(slider) {
		isLast.value = (slider.track.details.slides[totalSlides.value - 1]?.portion ?? 0) >= 0.98;
	},
	detailsChanged(slider) {
		isLast.value = (slider.track.details.slides[totalSlides.value - 1]?.portion ?? 0) >= 0.98;
	}
};

const handleClick = (url: string) => {
	const isRootUrl = url === "/";
	showCatalogGames.value = !isRootUrl;
	emit("clickTab", !isRootUrl ? url : "/home/lobby/");

	if (props.type === "button") {
		activeUrl.value = url;
	}

	nextTick(() => {
		scrollToActiveSlide();
	});
};

const handleGuestChange = (value: boolean) => {
	if (!value && props.showLobby) {
		lobbyActive();
	}

	nextTick(() => {
		setTimeout(() => {
			sliderRef?.value?.slider?.update();
			scrollToActiveSlide();
		}, 500);
	});
};

onMounted(() => {
	nextTick(() => {
		scrollToActiveSlide();
	});
});

const unwatchSliderUpdate = watch(
	() => isExpanded.value,
	async () => {
		await nextTick();
		setTimeout(() => {
			sliderRef.value?.slider?.update();
		}, 500);
	}
);

if (process.client) {
	watch(isGuest, handleGuestChange);

	watch(activeSlide, () => {
		setTimeout(() => {
			scrollToActiveSlide();
		}, 500);
	});
}

onUnmounted(() => {
	unwatchSliderUpdate();
});
</script>

<template>
	<nav class="games">
		<div class="search flex-center" @click="open('LazyOModalGameSearch')">
			<ASvg name="24/search" class="icon-search" />
		</div>
		<div class="box-list-nav">
			<AAnimationSkeleton v-if="!menu?.length">
				<ASkeleton width="100%" height="54px" />
			</AAnimationSkeleton>
			<ASlider v-else ref="sliderRef" class="game-navigation" :options="sliderOptions">
				<div v-if="showLobby" class="keen-slider__slide">
					<MNavigationGame
						link="/"
						:active="isTabActive('/')"
						icon-link="24/lobby"
						:title="t('Lobby')"
						data-tid="nav-lobby"
						:type="type"
						@click-tab="handleClick"
					/>
				</div>

				<div v-for="item in filteredMenu" :key="item.url" class="keen-slider__slide">
					<MNavigationGame
						:link="item.url || ''"
						:active="isTabActive(`${item.url}`)"
						:icon-html="item.img || ''"
						:title="item.title || ''"
						:data-tid="`nav-${getTitle(item?.title)}`"
						:type="type"
						@click-tab="handleClick"
					/>
				</div>
			</ASlider>

			<div class="prev flex-center" :class="{ hidden: sliderActiveSlide === 0 }" @click="sliderRef.slider?.prev()">
				<NuxtIcon name="16/right-slider" />
			</div>
			<div class="next flex-center" :class="{ hidden: isLast }" @click="sliderRef.slider?.next()">
				<NuxtIcon name="16/left-slider" />
			</div>
		</div>
	</nav>
</template>

<style scoped lang="scss">
nav.games {
	display: flex;
	gap: 8px;
	position: sticky;
	top: var(--top-height);
	background: var(--body-bg);
	padding: 6px 0;
	z-index: 10;
	transform: translateY(-2px);

	@include media-breakpoint-down(md) {
		width: calc(100% + 32px);
		transform: translate(-16px, -2px);
		padding: 6px 16px;
	}
}

.search {
	flex-shrink: 0;
	width: 62px;
	border-radius: 8px;
	background: var(--gray-800);
	cursor: pointer;
	transition: all 0.3s;

	&:hover {
		background: var(--gray-700);
	}

	.icon-search {
		font-size: 28px;
	}
}

.keen-slider__slide {
	overflow: visible;
}

.box-list-nav {
	position: relative;
	width: calc(100% - 70px);
	background: var(--gray-800);
	border-radius: var(--a-button-default-border-radius);
	border: 4px solid var(--gray-800);

	.game-navigation {
		width: 100%;
	}
}

.prev,
.next {
	position: absolute;
	top: 0;
	width: 24px;
	height: 100%;
	background: var(--gray-900);
	color: var(--primary-400);
	border-radius: 4px;
	user-select: none;

	@include media-breakpoint-down(sm) {
		display: none;
	}

	&:hover {
		background: var(--primary-400);
		color: var(--gray-900);
		cursor: pointer;
	}

	&.hidden {
		display: none;
	}

	&:before {
		content: "";
		background: linear-gradient(270deg, #202020 10%, rgba(32, 32, 32, 0) 100%);
		display: inline-flex;
		width: 30px;
		height: 100%;
		position: absolute;
		top: 0;
	}
}

.prev {
	left: 0;

	&:before {
		right: -30px;
		transform: rotate(180deg);
	}
}

.next {
	right: 0;

	&:before {
		left: -30px;
	}
}

a {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	white-space: nowrap;
	gap: 8px;
	padding: 8px 12px;
	color: var(--gray-200);
	text-decoration: none;
	border-radius: var(--a-button-default-border-radius);
	transition: all 200ms ease-in-out;
	text-transform: uppercase;

	&:hover {
		color: var(--gray-50);
		background: var(--gray-950);
	}
	&.active {
		color: var(--gray-50);
		background: var(--gray-950);
	}
}
</style>
